@charset "utf-8";
$breakpoints: (
    'sp':'screen and (max-width: 1024px)',
    'pc':'screen and (min-width: 1025px)'
) !default;
@mixin bp($breakpoint: sp) {
    @media #{map-get($breakpoints,$breakpoint)} {
        @content;
    }
}

$base: #000;
$base2: #6bf23a;
@mixin fnt-set{
font-feature-settings: "palt";
text-align: justify;
text-justify: inter-ideograph; 
}
@mixin fnt-arial {
font-family: Arial,sans-serif; 
}


@mixin fl {
    display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
}
@mixin fl_x {
    display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
}
@mixin fl_y {
    display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
}
@mixin fl_w {
    display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}
@mixin po($position: absolute,$top: 0,$left: 0,$right: 0,$bottom: 0) {
  position: $position;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
  margin: auto;
}
@mixin rsp-img{
	width: 100%;
	height: auto;
}

// * hack IE
$hack-ie11: "*::-ms-backdrop";
@mixin hack-ie($hack-ver:'') {
    // * IE8 hack
    @if $hack-ver == "ie8" {
        @media \0screen {
            @content;
        }
    }
    // * IE9-10 hack(add propary \9)
    @else if $hack-ver == "ie9-10" {
        @media all and (min-width:0\0) {
            @content;
        }
    }
    // * IE10 hack(add propary \9)
    @else if $hack-ver == "ie10" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
    // * IE11 hack(add selector *::-ms-backdrop)
    @else if $hack-ver == "ie11" {
        @media all and (-ms-high-contrast:none\0) {
            @content;
        }
    }
}
/*basic*/
.clearfix:after{content:".";display:block;clear:both;height:0;visibility:hidden;}.clearfix{min-height:1px;}.clear{clear:both;}
@include bp {
    .pc-s {display:none !important;}
}
@include bp(pc) {
    .sp-s {display:none !important;}
}
h1,h2,h3,h4,h5,h6{
	font-feature-settings: "palt";
}

.mb30{
margin-bottom: 30px;
}

.mb50{
margin-bottom: 50px;
}






